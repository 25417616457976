.Home {
  max-width: 85%;
  margin: auto;
}

input[type="submit"] {
  width: 100%;
  font-size: 18px;
}

