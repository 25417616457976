label {
  font-weight: bold;
  margin-bottom: 10px;
}

input[type="text"], textarea {
  width: 100%;
}

textarea {
  height: 300px;
}

input[type="submit"] {
  width: 100%;
  padding: 10px;
  font-size: 18px;
}

.apiform-container {
  display: block;
  max-width: 40%;
  float: left;
  height: auto;
}


.output-container {
  max-width: 60%;
  float: left;
  height: 100%;
  padding-left: 20px;

}

.container {
  display: flex;
  width: 95%;
  margin: 0 auto;
  padding: 30px 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}


@media (max-width: 800px) {
  h1 {
    text-align: center;
  }
  .container {
    display: inline-block;
    padding: 30px 20px;
    max-width: 90%;
  }
  .apiform-container {
    max-width: 100%;
    width: 100%;
  }
  .output-container {
    max-width: 100%;
    width: 100%;
    padding-left: 0px;
    padding-bottom: 20px;
  }
  .spinner-container {
    float: center;
    height: 50px;
  }
}

.codeMirror {
  font-size: 12px;
  height: 100%;
}

#property-test-title {
  margin-bottom: 10px;
}

.url-container{
  margin-bottom: 10px;
}

.examples-dropdown {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
}
