.footer {
  margin-top: 30px;
  height: auto;
  font-size: 1.6rem;
  width: 100%;
}

.footerLeft{
width: 30%;
float: left;
display:inline;
}

.footerRight{
width: 60%;
float: right;
display:inline;
}
